import React from 'react'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { uniqueId } from 'lodash'
import { Box, Flex } from 'rebass'
import styled from 'styled-components'

import Layout from '../components/layout/Layout'
import Container from '../components/layout/Container'
import Section from '../components/layout/Section'
import SEO from '../components/elements/SEO'
import { above, colors } from '../components/theme'

const FaqHero = styled(Box)`
  background-color: ${props => props.theme.colors.primary[500]};
  color: ${props => props.theme.colors.white};
`

const Faqs = ({ data }) => {
  const page = data.prismicFaqspage.data

  return (
    <Layout>
      <SEO
        meta_title={page.meta_title}
        meta_description={page.meta_description}
      />

      <FaqHero bg={colors.gray[800]} color={colors.white}>
        <BackgroundImage Tag="div" fluid={page.hero_image.fluid}>
          <Container py={[13, 14, 15]}>
            <Flex flexWrap="wrap">
              <Box width={[1, 1, 4 / 5, 1 / 2]} py={12}>
                <h1
                  css={`
                    margin-bottom: 0;
                  `}
                >
                  {page.hero_text.text}
                </h1>
              </Box>
            </Flex>
          </Container>
        </BackgroundImage>
      </FaqHero>

      <Section>
        <Container>
          <h2>{page.title.text}</h2>
          <Flex flexWrap="wrap" mx={-8}>
            {data.prismicFaqspageBodyFaqs.items.map(({ answer, question }) => (
              <Box key={`${uniqueId()}`} width={[1, 1 / 2]} px={8} mb={8}>
                <h3>{question.text}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: answer.html,
                  }}
                />
              </Box>
            ))}
          </Flex>
        </Container>
      </Section>
    </Layout>
  )
}

export default Faqs

export const query = graphql`
  query FaqsQuery {
    prismicFaqspage {
      data {
        hero_text {
          html
          text
        }
        hero_image {
          fluid(maxWidth: 1500) {
            ...GatsbyPrismicImageFluid_noBase64
          }
        }
        meta_description
        meta_title
        title {
          text
        }
      }
      id
      uid
    }
    prismicFaqspageBodyFaqs {
      items {
        answer {
          html
        }
        question {
          text
        }
      }
    }
  }
`
